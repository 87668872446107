@import '~@studyportals/styles-abstracts/variables/colors';
@import '~@studyportals/styles-abstracts/mixins/spacing';

.LogInWidget {
	@include PaddingAreas(1);
	margin: 1rem auto 0;
	border: 1px solid $GreyL;
	width: 16rem;
	border-radius: 3px;

	.SignInButton {
		@include PaddingAreas(0.5);
		@include MarginAreas(0.5, 0);
		display: block;
		width: 100%;
	}
}
