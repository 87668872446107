@import '~@studyportals/styles-abstracts/variables/_colors.scss';
@import '~@studyportals/styles-abstracts/variables/_z-index.scss';
@import '~@studyportals/styles-abstracts/mixins/_typography.scss';
@import '~@studyportals/styles-abstracts/mixins/_responsive.scss';

.TheMatchesOverview {
	position: relative;

	.TableActionsBar {
		position: absolute;
		z-index: $FirstFloor;
		/* Make button visible when running SMT stand-alone. */
		top: -0.6rem;
		right: 1rem;
		display: flex;
	}

	.MatchesOverviewTable {
		margin-top: 1rem;
		height: calc(100vh - 130px);

		.TableHeaderCell {
			@include TextStyle(Body);
		}

		.TableContentCell {
			@include TextStyle(Note);
			padding-top: 1rem;
		}

		.TableCell,
		.TableHeaderCell {
			border-right: 0.5px solid $GreyLL;
		}

		.ag-header {
			box-shadow: 0 5px 4px -4px $GreyLL;
		}
	}
}

/* If the session expiration message is visible, reserve an extra 60px of space. */
[data-wrapper-visible='true'] + div + .FullWidthContent .TheMatchesOverview .MatchesOverviewTable {
	height: calc(100vh - 190px);
}

#StudentMatchingDashboardMS .TheMatchesOverview .TableActionsBar {
	top: -3.5rem;
	right: 1.5rem;
}
