// Import abstracts
@import '~@studyportals/styles-abstracts/abstracts.scss';

// Import patterns
@import './button';
@import '~@studyportals/styles-components/components/ClickableText';
@import '~@studyportals/modal/modal.css';

$accent-color: $BrandBlue;
$selected-color: $GreyLLL;
$border-color: $GreyLL;
$primary-color: transparent;
$grid-size: 8px;
$cell-horizontal-padding: 16px;
$header-height: 40px;
$icon-size: 12px;
$icon-color: $GreyD;
$font-family: 'Open Sans', Arial, sans-serif;
$secondary-font-family: 'Open Sans', Arial, sans-serif;
$secondary-font-weight: normal;
$tooltip-background-color: $White;
$ag-icon-none: '\E902\E910';

@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/src/styles/ag-theme-material/sass/legacy/ag-theme-material-v22-compat';

#app {
	font-family: 'Open Sans', Arial, sans-serif;
	position: relative;
}

body {
	@include TextStyle(Body);
	@include MarginAreas(0);
	font-weight: 400;
	color: $GreyDD;
	background-color: $White;
	text-align: left;
}

.Hidden {
	display: none !important;
}
