@import '~@studyportals/styles-abstracts/variables/_colors.scss';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';
@import '~@studyportals/styles-abstracts/mixins/_typography.scss';

.ModalOverlay .ApproveBatchModalWrapper.ModalWrapper.ComplexModal {
	height: auto;
	width: 32rem;

	.CloseButton {
		color: $White;
		font-size: 1.5rem;
		line-height: 1.5rem;

		.Icon {
			@include MarginAreas(0.5, 0, 0);
		}
	}

	&.LoadingModalWrapper .CloseButton {
		color: $GreyDD;
	}

	.ContentWrapper {
		padding: 0;
	}
}

.ApproveBatchModal {
	font-family: 'Open Sans', Arial, sans-serif;

	.ApproveBatchModalHeader {
		background-color: $BrandBlue;
		@include PaddingAreas(1);

		.ApprovalBatchModalTitle {
			@include TextStyle(Body);
			@include MarginAreas(0);
			color: $White;
		}
	}

	.ApproveBatchModalContent {
		@include MarginAreas(0, 0.5);
		@include PaddingAreas(1);

		.SubmitReviewModalButtons {
			.DriverButton,
			.NavigatorButton {
				@include MarginAreas(0, 0, 0.5);
				width: 100%;
			}
		}

		.ConfirmationTextContainer {
			@include TextStyle(Body);
		}

		.ConfirmationButtonContainer {
			margin-top: 1.5rem;
			display: flex;
			justify-content: center;

			.PrimaryButton {
				margin-right: 1rem;
			}
		}
	}

	.LoadingProgressContent {
		@include PaddingAreas(1, 1.5);
		@include TextStyle(Body);
		text-align: center;

		.LoadingActiveText {
			margin: 0;
			text-align: left;
		}

		.SuccessIcon,
		.FailIcon {
			display: block;
			margin-top: 1rem;
			font-size: 2.5rem;
		}

		.SuccessIcon {
			color: $Green;
		}

		.FailIcon {
			color: $Red;
		}
	}
}
