@import '~@studyportals/styles-components/components/Button';
@import '~@studyportals/styles-abstracts/variables/colors';

.DriverButton {

	&.Green {
		background: $Green;
		border-color: $Green;
		color: $White;
		min-height: 2rem;
		padding: 0.25rem 0.75rem;

		&.ReviewEnrolment {
			padding: 0.1rem 1rem;
		}

		&:visited {
			color: $White;
		}

		&:hover {
			background: darkgreen;
			border-color: darkgreen;
			color: $White;

			@media (hover: none) {
				background: darkgreen;
				border-color: darkgreen;
				color: $White;
			}
		}
	}
}
