@import '~@studyportals/styles-abstracts/variables';
@import '~@studyportals/styles-abstracts/mixins';

.IncompleteWorkOrderTooltip {
	@include TextStyle(Note);
	position: absolute;
	display: block;
	width: 15rem;
	background: $White;
	box-shadow: 0 1px 5px rgba(49, 50, 51, 0.5);
	border-radius: 3px;
	color: $GreyD;
	text-align: center;
	margin: 0;
	margin-left: -7.5rem;
	padding: 0.75rem;
	box-sizing: border-box;

	&:before {
		display: block;
		content: ' ';
		position: absolute;
		top: -0.5rem;
		height: 0.8rem;
		left: calc(50% - 0.5rem);
		transform: rotate(45deg);
		width: 0.8rem;
		background-color: $White;
		border-top: 1px solid rgba(49, 50, 51, 0.2);
		border-left: 1px solid rgba(49, 50, 51, 0.2);
	}
}

/* By default AgGrid fades out a previous tooltip, which looks weird. Simply hide the fading-out tooltip. */
.ag-tooltip-hiding {
	display: none;
}
